import { Component, Pipe, PipeTransform, ViewEncapsulation, ViewChild, ViewContainerRef, Compiler, NgModule,Host,Directive } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserModule, Title, DomSanitizer } from '@angular/platform-browser';
import { Router, RouterModule, ActivatedRoute, Params } from '@angular/router';
import { Headers } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ShellService } from '../services/shell.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenInterceptor } from '../helpers/token.interceptor';
import { ErrorInterceptor } from '../helpers/error.interceptor';
import { AuthenticationService } from '../services/authentication.service';


import { IonicModule, NavController } from '@ionic/angular';


//import { MbscModule, MbscCalendarOptions, MbscEventcalendarOptions,  } from '@mobiscroll/angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import {Location} from '@angular/common';


declare var jquery: any;



@Directive({
    selector: '[dynamicHeader]'
  })
  export class DynamicHeaderDirective {
    constructor(
      private compiler: Compiler,
      private viewContainer: ViewContainerRef,
      private toastr: ToastrService,
      private _location: Location,
    ) { }


    public addHeader(title: string, headerTemplate: string, mainComponent: any) {

        @Component({ template: headerTemplate, encapsulation: ViewEncapsulation.Emulated })
          class HeaderComponent {
      
            main : any;
            pageTitle: string;
            constructor(
              private api: ApiService,
              public shell: ShellService,
              private router: Router,
              private storage: Storage) { }
      
              ngOnInit() {
                     
             this.pageTitle = title;
              this.main =  mainComponent;
            }
      
          }
      
          @NgModule({
            declarations: [
              HeaderComponent
              
            ],
            providers: [
              {
                provide: HTTP_INTERCEPTORS,
                useClass: TokenInterceptor,
                multi: true
              },
              {provide: HTTP_INTERCEPTORS,useClass: ErrorInterceptor,multi: true},
              AuthenticationService,
              JwtHelperService
            ],
            imports: [
              //MbscModule,
              CommonModule,
              BrowserModule,
              FormsModule,
              RouterModule,
              IonicModule,
              BrowserAnimationsModule,
              HttpClientModule,
              ToastrModule.forRoot(),
            ]
          })
          class HeaderModule { }
      
          if (this.viewContainer.length > 0) {
            this.viewContainer.remove();
          }
      
          this.compiler.clearCache();

          
          const mod = this.compiler.compileModuleAndAllComponentsSync(HeaderModule);
          const factory = mod.componentFactories.find((comp) =>
            comp.componentType === HeaderComponent
          );
          //cons
          this.viewContainer.createComponent(factory);
        }

        public back(){
          this._location.back();
        }
    }