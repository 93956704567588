import { MbscModule } from '@mobiscroll/angular';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { AppSettings } from './services/app-settings';
import { ToastService } from './services/toast-service';
import { LoadingService } from './services/loading-service';

import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import { IonicStorageModule } from '@ionic/storage';

import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtModule } from '@auth0/angular-jwt';

import { LoginComponent } from './login/login.component';
import { ApiService } from './services/api.service';
import { ShellService } from './services/shell.service';
import { AuthenticationService } from './services/authentication.service';
import { TokenInterceptor } from './helpers/token.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { DynamicHeaderDirective, DynamicPageDirective , DynamicFooterDirective } from '../app/directives';
import { MainPage } from '../app/main/main'
import { HTTP_INTERCEPTORS ,HttpClientModule} from "@angular/common/http";
import { User } from '../app/models/data.types'

import { Network } from '@ionic-native/network/ngx';
import { AuthGuard } from './helpers/index';
import { Capacitor } from '@capacitor/core';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { FTP } from '@ionic-native/ftp/ngx';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { MessagingService} from "./services/messaging.service";
import { OpenPage } from './open/open.page';
import { ReceivedPage } from './received/received.page';
import { UpcomingPage } from './upcoming/upcoming.page';
import { HomePage } from './home/home.page';

import { NgApexchartsModule } from 'ng-apexcharts';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions} from '@ionic-native/media-capture/ngx';
import { File } from '@ionic-native/file/ngx';
import { QuillModule } from 'ngx-quill'


export function tokenGetter() {
  let currentUser : User;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  if(currentUser == null)
  {
      return "";
  }
  return currentUser.token;
}


@NgModule({
  declarations: [MainPage,OpenPage,ReceivedPage,UpcomingPage,HomePage, AppComponent,DynamicHeaderDirective, DynamicPageDirective , DynamicFooterDirective ],
  exports: [MainPage,OpenPage,ReceivedPage,UpcomingPage,HomePage,DynamicHeaderDirective, DynamicPageDirective , DynamicFooterDirective ],
  entryComponents: [],
  imports: [ 
    MbscModule,  
    FormsModule,  
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgApexchartsModule,
    HttpModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
       }
      }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(AppSettings.FIREBASE_CONFIG),
    AngularFireDatabaseModule, AngularFireAuthModule,
    BrowserModule, HttpModule, HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    SuperTabsModule.forRoot(),
    AppRoutingModule,
    QuillModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS,useClass: TokenInterceptor,multi: true},
    {provide: HTTP_INTERCEPTORS,useClass: ErrorInterceptor,multi: true},
    StatusBar,
    SplashScreen,
    CallNumber,
    ImagePicker,
    FTP,
    ApiService,
    ShellService,
    AuthenticationService,
    JwtHelperService,
    OneSignal,
    LocalNotifications,
    MessagingService,
    Network,
    AuthGuard,
    File,
    //{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    StatusBar, BarcodeScanner,
    SplashScreen, ToastService, LoadingService,Camera,MediaCapture
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
