import { Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import { NonQuery, DataTable, Session, User, PageFunction, LocalAPIs } from '../models/data.types';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/catch';
import { Storage } from '@ionic/storage';

@Injectable()
export class ApiService {

    isLoggedIn: boolean = false;
    roles: string[];
    redirectUrl: string;
    defaultHeaders: HttpHeaders;
    authHeaders: HttpHeaders;
    tokenHeaders: HttpHeaders;
    headers: HttpHeaders;

    apiUrl: string;
    baseUrl: string;
    tokenUrl: string;
    userName: string;
    token: any;
    user: User;
    IsProd: boolean = true;


    state: any;

    constructor(private http: HttpClient, private router: Router, private storage: Storage) {
        this.defaultHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.tokenHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        this.baseUrl = environment.apiUrl;
        //this.getApiUrl()= `${this.baseUrl}api/`;
        //this.gettokenUrl() = `${this.baseUrl}token`;
        this.state = router.routerState.snapshot;

        if (localStorage.getItem("APIEndPoint") === null) {
            localStorage.setItem('APIEndPoint', "https://app.eliant.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.eliant.com/service/token");

            localStorage.setItem('HttpUrl', "https://app.eliant.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\app.eliant.com\\Files");
        }

        if (localStorage.getItem("APIEndPoint").indexOf("framework") > -1) {

            this.IsProd = false;
        }
    }

    getApiUrl() {
        if (localStorage.getItem("APIEndPoint") === null) {
            localStorage.setItem('APIEndPoint', "https://app.eliant.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.eliant.com/service/token");

            localStorage.setItem('HttpUrl', "https://app.eliant.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\app.eliant.com\\Files");
        }

        if (localStorage.getItem("APIEndPoint").indexOf("framework") > -1) {

            this.IsProd = false;
        }
        return localStorage.getItem("APIEndPoint");
    }

    gettokenUrl() {
        if (localStorage.getItem("APIEndPoint") === null) {
            localStorage.setItem('APIEndPoint', "https://app.eliant.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.eliant.com/service/token");

            localStorage.setItem('HttpUrl', "https://app.eliant.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\app.eliant.com\\Files");
        }

        if (localStorage.getItem("APIEndPoint").indexOf("framework") > -1) {

            this.IsProd = false;
        }
        return localStorage.getItem("TokenUrl");
    }

    gethttpUrl() {
        if (localStorage.getItem("APIEndPoint") === null) {
            localStorage.setItem('APIEndPoint', "https://app.eliant.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.eliant.com/service/token");
            localStorage.setItem('HttpUrl', "https://app.eliant.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\app.eliant.com\\Files");
        }

        if (localStorage.getItem("APIEndPoint").indexOf("-dev") > -1) {

            this.IsProd = false;
        }
        return localStorage.getItem("HttpUrl");
    }

    getfolderPath() {
        if (localStorage.getItem("APIEndPoint") === null) {
            localStorage.setItem('APIEndPoint', "https://app.eliant.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.eliant.com/service/token");
            localStorage.setItem('HttpUrl', "https://app.eliant.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\app.eliant.com\\Files");
        }

        if (localStorage.getItem("APIEndPoint").indexOf("-dev") > -1) {

            this.IsProd = false;
        }
        return localStorage.getItem("FolderPath");
    }

    loadUser() {

        if (localStorage.getItem('currentUser') != null) {

            this.user = JSON.parse(localStorage.getItem('currentUser'));
            this.isLoggedIn = true;

            this.authHeaders = new HttpHeaders();
            this.authHeaders.append('Content-Type', 'application/json');
            this.authHeaders.append('Authorization', 'bearer ' + this.user.token);

            console.log('loaded user from cache...');
        }
    }

    login(userName: string, passWord: string): Promise<Session> {
        let tokenRequest = 'grant_type=password&username=' + userName + '&password=' + passWord;

        return this.http.post(
            this.gettokenUrl(),
            tokenRequest, {
            headers: this.tokenHeaders
        })
            .toPromise()
            .then(x => {
                this.isLoggedIn = true;
                return x;
            })
            .catch(x => x);
    }

    adLogin(userName: string, passWord: string): Promise<any> {
        let tokenRequest = 'username=' + userName + '&password=' + passWord;
        return this.http.post(
            environment.adTokenUrl,
            tokenRequest, {
            headers: this.tokenHeaders
        })
            .toPromise()
            .then(x => {
                this.isLoggedIn = true;
                return x;
            })
            .catch(x => x);
    }

    logout() {
        localStorage.clear();
        this.authHeaders = null;
        this.storage.clear();
        this.IsProd = true;
        this.router.navigate([environment.loginRoute]);
    }

    logoutWithoutRedirect() {
        localStorage.removeItem("currentUser");
        this.authHeaders = null;
    }

    get<T>(url: string): Promise<T> {
        return this.http.get(this.getApiUrl() + url, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }

    put(url: string, o: any) {
        this.http.put(this.getApiUrl() + url, o, { headers: this.authHeaders }).toPromise().catch(this.handleError);
    }

    putAs<T>(url: string, o: any): Promise<T> {
        return this.http.put(this.getApiUrl() + url, o, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }

    post(url: string, o: any) {
        this.http.post(this.getApiUrl() + url, o, { headers: this.authHeaders }).toPromise().catch(this.handleError);
    }

    postAs<T>(url: string, o: any): Promise<T> {
        return this.http.post(this.getApiUrl() + url, o, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }

    delete<T>(url: string): Promise<T> {
        return this.http.delete(this.getApiUrl() + url, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.handleError);
    }

    postExternal<T>(url: string, o: any): Promise<T> {
        return this.http.post(url, o).toPromise().then(x => x as T).catch(this.handleError);
    }

    download(url: string, o: any, fileName: string, fileType: string): Promise<Blob> {
        return this.http.post(url, o, { responseType: 'blob' }).map(
            (res) => {

                var blob = new Blob([res], { type: fileType });
                var blobURL = window.URL.createObjectURL(blob);
                var anchor = document.createElement("a");
                anchor.download = fileName;
                anchor.href = blobURL;
                anchor.click();

                return blob;
            }).toPromise().then(x => x as Blob).catch(this.handleError);
    }


    getExternal<T>(url: string): Promise<T> {
        return this.http.get(url).toPromise().then(x => x as T).catch(this.handleError);
    }

    private handleError(error: any) {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    getNoError<T>(url: string): Promise<T> {
        return this.http.get(this.getApiUrl() + url, { headers: this.authHeaders }).toPromise().then(x => x as T).catch(this.SkipError);
    }

    private SkipError(error: any) {
        console.log('LocalQuery');
        return Promise.resolve(error);
    }

    userLogin(userName: string, passWord: string) {
        let tokenRequest = 'grant_type=password&username=' + userName + '&password=' + passWord;
        return this.http.post(
            this.gettokenUrl(),
            tokenRequest, {
            headers: this.tokenHeaders
        })
            .map(x => {
                localStorage.setItem('session', JSON.stringify(x));
                let session = JSON.parse(JSON.stringify(x));

                let user: User = new User();
                user.username = userName;
                user.password = passWord;
                user.token = JSON.parse(session._body).access_token.toString();
                localStorage.setItem('currentUser', JSON.stringify(user));

                this.isLoggedIn = true;
                return x;
            })
            .catch(x => x);
    }

    ChangeEnvironment(changeToEnvironment) {
        debugger;
        this.logout();
        if (changeToEnvironment === 'Production') {

            localStorage.setItem('APIEndPoint', "https://app.eliant.com/service/api/");
            localStorage.setItem('TokenUrl', "https://app.eliant.com/service/token");
            localStorage.setItem('HttpUrl', "https://app.eliant.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\app.eliant.com\\Files");
            this.IsProd = true;
        }
        else {
            localStorage.setItem('APIEndPoint', "https://framework.eliant.com/service/api/");
            localStorage.setItem('TokenUrl', "https://framework.eliant.com/service/token");

            localStorage.setItem('HttpUrl', "https://framework.eliant.com/files/");
            localStorage.setItem('FolderPath', "C:\\inetpub\\framework.eliant.com\\Files");
            this.IsProd = false;
        }
        console.log(localStorage.getItem("APIEndPoint"));
    }

    GetAppVersion(){
        return environment.appVersion;
    }

    private handleApiError(error: any, LocalQueryCalls: LocalAPIs, resolve: any) {
        LocalQueryCalls.Errors.push(error);
        return resolve(LocalQueryCalls);
    }
}