import { Component, ViewChild, ViewEncapsulation, ViewContainerRef, Compiler, NgModule,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ApiService } from './services/api.service';
import { ShellService } from './services/shell.service';
import { environment } from './../environments/environment'
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule, NavController } from '@ionic/angular';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Router } from "@angular/router";
import { FTP } from '@ionic-native/ftp/ngx';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  template: `<ng-container #dynamicAppComponent></ng-container>`
})
export class AppComponent {
  public appPages = [];
  isConnected:boolean;
  @ViewChild('dynamicAppComponent', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private apiService: ApiService,
    private compiler: Compiler,
    private shellService: ShellService,
    private oneSignal: OneSignal,
    private localNotifications: LocalNotifications,
    private fTP: FTP,
    private router: Router,
    public nav: NavController
  ) {
    this.initializeApp();
    //shellService.loadShell().then(() => this.addComponent(shellService.shell.template));
    this.apiService.postAs<any>('data/doquery/getshellbyname', { name: environment.shellName, username: environment.username }).then(x => {
      this.addComponent(x.shell.template);
    }).catch(e => {
      console.log(e);
      this.addComponent(`<ion-app>
      <ion-split-pane when="false" contentId="main">
        <ion-router-outlet id="main"></ion-router-outlet>
      </ion-split-pane>
    </ion-app>`);
    this.nav.navigateRoot(['/no-connection']);

    }
    );
  }

 

  networkSubscriber(): void {
    this.shellService
        .getNetworkStatus()
        .pipe(debounceTime(300))
        .subscribe((connected: boolean) => {
            this.isConnected = connected;
            console.log('[Home] isConnected', this.isConnected);
            this.handleNotConnected(connected);
        });
}

  handleNotConnected(connected: boolean) {
    this.shellService.isOnline=connected;
    if(!connected){
      this.nav.navigateRoot(['/no-connection']);
    }
    else
    {

    }
  }

  initializeApp() {
    let self = this;
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (this.platform.is('cordova')) {

        this.setupPush();
        this.connectFTP();
      }
      console.log(this.shellService.getNetworkType());
      console.log('[Home] OnInit');
      this.networkSubscriber();
    });

    this.platform.backButton.subscribeWithPriority(10, () => {
      self.nav.back();
    });
  }

  connectFTP() {
    this.fTP.connect(environment.ftpHost, environment.ftpUsername, environment.ftpPassword)
      .then((res: any) => console.log('Login successful', res))
      .catch((error: any) => console.error(error));
  }

  setupPush() {
    let self = this;

    this.oneSignal.startInit('344848f6-e1ca-4ba3-812e-4e1aaf18061e', 'com.idbpro.eliant');

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);


    this.oneSignal.handleNotificationReceived().subscribe(data => {

      console.log('New Notification Received');

      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;


      console.log('msg: ' + msg);

      console.log('title: ' + title);

      console.log(additionalData);

      if (additionalData != null) {
        if (additionalData.page.toLowerCase().indexOf('notes') > -1) {
          let split = additionalData.page.split('-', 2);
          if (split.length > 1) {
            self.router.navigate(["/tabs/tabs/main/homeowner-notes"], { queryParams: { id: split[1] } });
          }
        }
        else {
          switch (additionalData.page) {
            case "received":
              self.router.navigate(["/tabs/tabs/received"]);
              break;
            case "open":
              self.router.navigate(["/tabs/tabs/open"]);
              break;
            case "upcoming":
              self.router.navigate(["/tabs/tabs/upcoming"]);
              break;
            default:
              self.router.navigate(["/tabs/tabs/home"]);
          }
        }
      }
      else {
        self.router.navigate(["/tabs/tabs/home"]);
      }

      this.localNotifications.schedule({
        id: this.getHash(title + msg + new Date().getMilliseconds),
        title: title,
        text: msg,
      });

      //this.showAlert(title, msg, additionalData.task);
      //this.showAlert(title, msg, null);
    });

    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // let additionalData = data.notification.payload.additionalData;

      // this.showAlert('Notification opened', 'You already read this before', additionalData.task);
    });

    this.oneSignal.endInit();
  }

  getHash(input) {
    var hash = 0, len = input.length;
    for (var i = 0; i < len; i++) {
      hash = ((hash << 5) - hash) + input.charCodeAt(i);
      hash |= 0;
    }
    return hash;
  }

  private addComponent(componentTemplate: string) {

    @Component({ template: componentTemplate, encapsulation: ViewEncapsulation.None })
    class DynamicAppComponent { constructor(private shellService: ShellService, private apiService: ApiService) { } }

    @NgModule({
      declarations: [
        DynamicAppComponent
      ],
      imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        IonicModule,
      ]
    })
    class DynamicAppComponentModule { }

    const mod = this.compiler.compileModuleAndAllComponentsSync(DynamicAppComponentModule);
    const factory = mod.componentFactories.find((comp) =>
      comp.componentType === DynamicAppComponent
    );
    const component = this.container.createComponent(factory);
  }
}
