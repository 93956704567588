import { Component, Pipe, PipeTransform, ViewEncapsulation, ViewChild, ViewContainerRef, Compiler, NgModule,Host,Directive } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserModule, Title, DomSanitizer } from '@angular/platform-browser';
import { Router, RouterModule, ActivatedRoute, Params } from '@angular/router';
import { Headers } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ShellService } from '../services/shell.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenInterceptor } from '../helpers/token.interceptor';
import { ErrorInterceptor } from '../helpers/error.interceptor';
import { AuthenticationService } from '../services/authentication.service';


import { IonicModule, NavController } from '@ionic/angular';


//import { MbscModule, MbscCalendarOptions, MbscEventcalendarOptions,  } from '@mobiscroll/angular';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { DynamicPageDirective } from '../directives' ;
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';

declare var jquery: any;



@Directive({
    selector: '[dynamicFooter]'
  })
  export class DynamicFooterDirective {
    constructor(
      private compiler: Compiler,
      private viewContainer: ViewContainerRef,
      private toastr: ToastrService
    ) { }


    public addFooter(footerTemplate: string, mainComponent: any) {
 
        @Component({ template: footerTemplate, encapsulation: ViewEncapsulation.Emulated })
          class FooterComponent {
      
            main: any;
            constructor(
              private api: ApiService,
              public shell: ShellService,
              private router: Router,
              private storage: Storage) { }
      
              ngOnInit() {
                     
              this.main =  mainComponent;
            }
      
          }
      
          @NgModule({
            declarations: [
              FooterComponent
              
            ],
            providers: [
              {
                provide: HTTP_INTERCEPTORS,
                useClass: TokenInterceptor,
                multi: true
              },
              {provide: HTTP_INTERCEPTORS,useClass: ErrorInterceptor,multi: true},
              AuthenticationService,
              JwtHelperService
            ],
            imports: [
              //MbscModule,
              CommonModule,
              BrowserModule,
              FormsModule,
              RouterModule,
              IonicModule,
              BrowserAnimationsModule,
              HttpClientModule,
              ToastrModule.forRoot(),
            ]
          })
          class FooterModule { }
      
          if (this.viewContainer.length > 0) {
            this.viewContainer.remove();
          }
      
          this.compiler.clearCache();

          
          const mod = this.compiler.compileModuleAndAllComponentsSync(FooterModule);
          const factory = mod.componentFactories.find((comp) =>
            comp.componentType === FooterComponent
          );
          //const footerFactory = mod.componentFactories.find((comp) =>
          //  comp.componentType === FooterComponent
          //);
          this.viewContainer.createComponent(factory);
        }

    }