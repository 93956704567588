import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { NonQuery, DataTable, Session, User, Shell, Page, PageFunction, RequestParameter, QueryResults } from '../models/data.types';
import { environment } from '../../environments/environment'
import { ApiService } from './api.service';
import { Network } from '@ionic-native/network/ngx';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { forEach } from '@angular/router/esm2015/src/utils/collection';
import { StorageService, StorageItem } from '../services/storage.service';
import { Jsonp } from '@angular/http/src/http';
import { promise } from 'selenium-webdriver';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { LoadingController } from '@ionic/angular';
//import { MbscModule, MbscCalendarOptions, MbscEventcalendarOptions } from '@mobiscroll/angular';
import { IonicModule, NavController } from '@ionic/angular';


@Injectable()
export class ShellService implements Resolve<void> {

    shell: any;
    shellState: any = {};
    isOnline: boolean = false;
    syncColor: string = "blue";
    user: User;
    syncFinished: boolean;
    LastSyncDate: string = 'Never';
    shellLoaded: boolean;
    shellHasError: boolean;
    timeOut: number = 1000;

    private online$: Observable<boolean> = undefined;

    constructor(
        private http: Http,
        private router: Router,
        private apiService: ApiService,
        private network: Network,
        private storage: Storage,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private storageService: StorageService,
        public loadingController: LoadingController,
        public nav: NavController) {

        //this.watchNetwork();

        this.online$ = Observable.create(observer => {
            observer.next(true);
        }).pipe(mapTo(true));

        if (this.platform.is('cordova')) {
            // on Device
            this.online$ = merge(
                this.network.onConnect().pipe(mapTo(true)),
                this.network.onDisconnect().pipe(mapTo(false))
            );
        } else {
            // on Browser
            this.online$ = merge(
                of(navigator.onLine),
                fromEvent(window, 'online').pipe(mapTo(true)),
                fromEvent(window, 'offline').pipe(mapTo(false))
            );
        }
    }

    public getNetworkType(): string {
        return this.network.type;
    }


    public getNetworkStatus(): Observable<boolean> {
        return this.online$;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
        return this.loadShell();
    }


    doQuery(apiName: string): any {
        return new Promise(resolve => {
            let queryResults: QueryResults;
            this.storageService.getTable('LocalQueries').then(storageItem => {
                let queries: PageFunction[] = JSON.parse(storageItem.value);
                let localQuery: PageFunction = queries.filter(item => item.Name === apiName)[0];
                if (localQuery) {
                    console.log('Local Query Found: ' + apiName);
                    queryResults = new QueryResults();
                    queryResults = localQuery.Results;
                }
                resolve(queryResults);
            });
        });
    }

    loadShell() {
        var promise = new Promise<void>((resolve, reject) => {
            //load user info first
            this.apiService.loadUser();
            if (this.shell != null) {
                resolve();
            }
            else if (localStorage.getItem('shell') === null || localStorage.getItem('shell') === "undefined") {
                this.loadShellOnly().then(() => resolve());
            }
            else {
                this.shell = JSON.parse(localStorage.getItem('shell'));
                eval('var _this=this;' + this.shell.script);
                console.log('loaded shell scripts from cache...');
                resolve();
            }
        });
        return promise;
    }
    loadShellOnly() {
        debugger;
        this.shellLoaded = false;
        this.shellHasError = false;
        this.user = JSON.parse(localStorage.getItem('currentUser'));

        return this.apiService.postAs<any>('data/doquery/getshellbyname', { name: environment.shellName, userName: this.user === null ? 'All' : this.user.username }).then(x => {
            this.shell = x.shell;
            this.shell.loaded = undefined;
            localStorage.setItem('shell', JSON.stringify(x.shell));
            eval('var _this=this;' + this.shell.script);
            console.log('loaded shell scripts live...');
        });
    }


    refreshShell() {

        this.shellLoaded = false;
        this.shellHasError = false;
        this.user = JSON.parse(localStorage.getItem('currentUser'));



        return this.apiService.postAs<any>('data/doquery/getshellbyname', { name: environment.shellName, userName: this.user === null ? 'All' : this.user.username }).then(x => {

            localStorage.setItem('shell', JSON.stringify(x.shell));
            this.shell = x.shell;
            this.shell.loaded = false;
            var localAPICount = parseInt(x.shell.localapicount);
            eval('var _this=this;' + this.shell.script);
            console.log('loaded shell scripts live...');

            this.shellLoaded = true;
            this.shellHasError = true;
            localStorage.setItem('shell', JSON.stringify(this.shell));

            this.loadingController.dismiss();

        });
    }

    getSyncColor() {
        if (this.isOnline) {
            return "blue";
        }
        else {
            return "red";
        }
    }
    watchNetwork() {
        let self = this;
        // watch network for a disconnect
        let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
            console.log('Network Disconnected :(((');
            this.isOnline = false;
            this.syncColor = "red";
        });

        // watch network for a connection
        let connectSubscription = this.network.onConnect().subscribe(() => {
            setTimeout(() => {
                if (this.network.type === 'wifi') {
                    console.log('we got a wifi connection, woohoo!');
                    this.isOnline = true;
                    this.syncColor = "blue";
                }
            }, 3000);
        });
        if (this.platform.is('cordova')) {
            // on Device
            merge(
                this.network.onConnect().pipe(mapTo(true)),
                this.network.onDisconnect().pipe(mapTo(false))
            ).subscribe((data: boolean) => {
                console.log('Network Status Changed: ' + (data === true ? 'Connected' : 'Disconnected '));
                this.isOnline = data;
                if (this.isOnline) {
                    this.syncColor = "blue";
                }
                else {
                    this.syncColor = "red";
                    this.nav.navigateRoot(['/no-connection']);

                }
                console.log(this.syncColor);

            });
        } else {
            merge(
                of(navigator.onLine),
                fromEvent(window, 'online').pipe(mapTo(true)),
                fromEvent(window, 'offline').pipe(mapTo(false))
            ).subscribe((data: boolean) => {
                console.log('Network Status Changed: ' + (data === true ? 'Connected' : 'Disconnected '));
                this.isOnline = data;
                if (this.isOnline) {
                    this.syncColor = "blue";
                }
                else {
                    this.syncColor = "red";
                    this.nav.navigateRoot(['/no-connection']);

                }
                console.log(this.syncColor);

            });
        }
    }

    public getDate() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        var day = date.getDate();
        var dayString = '';
        var month = date.getMonth() + 1;
        var monthString = '';
        var year = date.getFullYear();
        if (month < 10)
            monthString = '0' + month.toString();
        else
            monthString = month.toString();
        if (day < 10)
            dayString = '0' + day.toString();
        else
            dayString = day.toString();

        var dateString = monthString + '/' + dayString + '/' + year + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();

        return dateString;
    }
}
