import { Component, ViewChild ,ContentChild,ElementRef} from '@angular/core';
import { Router,  ActivatedRoute, Params ,NavigationEnd} from '@angular/router';
import { ApiService } from '../services/api.service';
import { QueryStringParameter, Page,User} from '../models/data.types';
import { DynamicHeaderDirective, DynamicPageDirective , DynamicFooterDirective} from  '../directives';
import { Headers } from '@angular/http';
import { ViewContainerRef, Compiler} from '@angular/core';
import { HttpHeaders} from "@angular/common/http";
import { Subscription,combineLatest } from "rxjs";
import { Observable } from 'rxjs/Observable';
import { map, startWith,withLatestFrom } from 'rxjs/operators';
import { IonicModule ,NavController} from '@ionic/angular';
import { BrowserModule, Title, DomSanitizer } from '@angular/platform-browser';
import {Location} from '@angular/common';

declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.html'
})
export class MainPage {

  sub: any;
  pageName: string;
  pageTitle: string;
  pageId: number;
  page: Page = new Page();
  currentUser: any = {};
  pagename: string;
  title: string;


  @ViewChild(DynamicHeaderDirective) dynamicHeader: DynamicHeaderDirective;
  @ViewChild(DynamicPageDirective) dynamicPage  : DynamicPageDirective;
  @ViewChild(DynamicFooterDirective) dynamicFooter: DynamicFooterDirective;
 

  constructor(private compiler: Compiler,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public nav: NavController,
    private sanitizer: DomSanitizer,
    private _location: Location) {

  }

  back(){
    this._location.back();
  }

  ionViewWillEnter() {
    this.sub = this.route.params.subscribe(params => {
      if (params['id'] == null) {
        this.pagename = 'SystemDashboardPage';
      }
      else {
        this.pagename = params['id'];
      }

      let body = JSON.parse('{"route": "'+this.pagename +'"}');
      
      this.LoadDynamicPage(this.pagename,this.dynamicHeader,this.dynamicPage,this.dynamicFooter);  
   });
  }

  // ngAfterViewInit() {
      
  //     this.sub = this.route.params.subscribe(params => {

  //       if (params['id'] == null) {
  //         this.pagename = 'SystemDashboardPage';
  //       }
  //       else {
  //         this.pagename = params['id'];
  //       }

  //       let body = JSON.parse('{"route": "'+this.pagename +'"}');

  //       this.LoadDynamicPage(this.pagename,this.dynamicHeader,this.dynamicPage,this.dynamicFooter);  
  //   });
  // }



  LoadDynamicPage(pageName : String,
                  dynamicHeaderDirective : DynamicHeaderDirective ,
                  dynamicPageDirective : DynamicPageDirective,
                  dynamicFooterDirective : DynamicFooterDirective) 
  {
    let currentUser : User;
    currentUser = JSON.parse(localStorage.getItem('currentUser'));
    
    if (currentUser != null) {

      this.apiService.authHeaders = new HttpHeaders();
      this.apiService.authHeaders.append('Content-Type', 'application/json');
      this.apiService.authHeaders.append(
        'Authorization', 'bearer ' + currentUser.token
      );
    }

    

    return this.apiService.get<Page>('pages/getpagebyroute/' + pageName).then(response => {
   
      let queryStringparams: QueryStringParameter[] = [];

      let pArray = Reflect.ownKeys(this.route.snapshot.queryParams);

      pArray.forEach(element => {
        let qsp = new QueryStringParameter();
        qsp.Name = element.toString();
        qsp.Value = Reflect.get(this.route.snapshot.queryParams, element.toString());
        queryStringparams.push(qsp);
      });

      this.page = response;

      this.page.Header = response.Header === null ?`<ion-header>
      <ion-toolbar  style="--min-height:65px">
        <ion-buttons slot="start" style="width:20%;position:absolute;bottom:2px">
        </ion-buttons>
        <ion-title><ion-img src="assets/imgs/logo/Eliant Ex Manag.png" style="height:80px"></ion-img></ion-title>
        <ion-buttons slot="end" style="width:20%;position:absolute;right:0;bottom:10px;color:#aeb0ae">
        <ion-icon slot="icon-only" name="search" style="font-size:32px" [routerLink]="['/tabs/tabs/main/search']"></ion-icon>
              </ion-buttons>
        </ion-toolbar>
      
    </ion-header>` 
      : response.Header;

      /*this.page.Footer = response.Footer === null ? `
        <ion-toolbar style="bottom:0;font-size:12px;--background:#12bcc1;--color:#FFFFFF;--padding-bottom:0;--padding-top:0;--padding-start:0;--padding-end:0;">   
          <ion-row style="background-color:#12bcc1">
          <ion-col size="3" [routerLink]="['/tabs/tabs/home']" class="ion-padding" style="border-right: #11a6ab thin solid;`+(this.page.Route=="mobile-home"?'background-color:#11a6ab;':'')+`">
          <div class="ion-text-center" style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
          <ion-img src="assets/imgs/logo/Eliant-Footer-icons_Home.png" style="height:35px"></ion-img>
                     <span class="mt-2" style="font-size:14px">Home</span>
          </div>
        </ion-col>
        <ion-col size="3" [routerLink]="['/tabs/tabs/upcoming']" class="ion-padding" style="border-right: #11a6ab thin solid;`+(this.page.Route=="mobile-upcoming"?'background-color:#11a6ab;':'')+`">
        <div class="ion-text-center" style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
        <ion-badge color="danger" style="background-color:#000000" *ngIf="main.upcoming>0">{{main.upcoming}}</ion-badge>
        <ion-img src="assets/imgs/logo/Eliant-Footer-icons_Upcoming.png" style="height:35px"></ion-img>         
         <span class="mt-2" style="font-size:14px">Upcoming</span>
        </div>
      </ion-col>
        <ion-col size="3" [routerLink]="['/tabs/tabs/open']" class="ion-padding" style="border-right: #11a6ab thin solid;`+(this.page.Route=="mobile-open"?'background-color:#11a6ab;':'')+`">
          <div class="ion-text-center" style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
          <ion-badge color="danger" style="background-color:#000000" *ngIf="main.opened>0">{{main.opened}}</ion-badge>
          <ion-img src="assets/imgs/logo/Eliant-Footer-icons_Open.png" style="height:35px"></ion-img>            
           <span class="mt-2" style="font-size:14px">Open</span>
          </div>
        </ion-col>

        <ion-col size="3" [routerLink]="['/tabs/tabs/received']" class="ion-padding" style="`+(this.page.Route=="mobile-received"?'background-color:#11a6ab;':'')+`">
          <div class="ion-text-center" style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
          <ion-badge color="danger" style="background-color:#000000" *ngIf="main.received>0">{{main.received}}</ion-badge>
          <ion-img src="assets/imgs/logo/Eliant-Footer-icons_Received.png" style="height:35px"></ion-img>           
           <span class="mt-2" style="font-size:14px">Received</span>
          </div>
        </ion-col>
            </ion-row>
      </ion-toolbar>`: response.Footer;
*/

     var mainComponent = dynamicPageDirective.addComponent(this.page,queryStringparams);

      dynamicHeaderDirective.addHeader(this.page.Title,this.page.Header,mainComponent.instance);
      
      dynamicFooterDirective.addFooter(this.page.Footer,mainComponent.instance);

    }).catch(e => {
        if (e.status === 401) {
            //  this.router.navigate(['/page/login']);
            console.log(e);

            this.apiService.get<Page>('pages/getpagebyroute/401').
            then(response => {
              dynamicPageDirective.addComponent(response,null);
            });

            
            return "";
          }
          else
          {
            return "404";
          }
       
      });
    
    };

}
