import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ShellService } from '../services/shell.service';
import { IonicModule, NavController } from '@ionic/angular';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private shellService: ShellService
        ,public nav: NavController) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            if (err.status == 0) {
                let type = this.shellService.getNetworkType();
                if (type == "unknown" || type == "none" || type == undefined) {
                    this.shellService.isOnline = false;
                    //this.nav.navigateRoot(['/no-connection']);
                }

            }
            //const error = err.error?.ErrorMessage || err.error?.error_description || err.statusText;
            return throwError(err);
        }))
    }
}