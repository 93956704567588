
export const environment = {
  production: false,
  apiUrl: 'https://framework.eliant.com/service/',
  defaultPage: 'main/home',
  loginPage: 'eliant-login',
  shellName: 'eliant-IONIC',
  loginRoute: '/eliant-login',
  adTokenUrl: 'https://framework.eliant.com/service/api/Account/AdLogin',
  tokenUrl: 'https://framework.eliant.com/service/token',
  username: 'rob@idbpro.com',
  ftpHost:'52.8.189.224',
  ftpUsername:'domrepphoto',
  ftpPassword:'b5qVNvMH6p8FpY5E',
  appVersion:'2.0'
};
