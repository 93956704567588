import { Component, Pipe, PipeTransform, ViewEncapsulation, ViewChild, ViewContainerRef, Compiler, NgModule, Host, Directive } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserModule, Title, DomSanitizer } from '@angular/platform-browser';
import { Router, RouterModule, ActivatedRoute, Params } from '@angular/router';
import { Headers } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ShellService } from '../services/shell.service';
import { DataTable, RequestParameter, UploadResponse, QueryStringParameter, NonQuery, Scalar, SingleObject, Page, PageFunction, ReturnType, DictionaryItem, User } from '../models/data.types';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenInterceptor } from '../helpers/token.interceptor';
import { ErrorInterceptor } from '../helpers/error.interceptor';
import { AuthenticationService } from '../services/authentication.service';
import { MbscModule, mobiscroll, MbscCalendarOptions, MbscEventcalendarOptions, MbscFormOptions, MbscRangeOptions, MbscListviewOptions } from '@mobiscroll/angular';
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart
} from "ng-apexcharts";
import { NgApexchartsModule } from "ng-apexcharts";



import { IonicModule, NavController } from '@ionic/angular';


//import { MbscModule, MbscCalendarOptions, MbscEventcalendarOptions } from '@mobiscroll/angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { FTP } from '@ionic-native/ftp/ngx';

import { MessagingService } from '../services/messaging.service'
import { SurveyCounts } from '../models/data.types';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions } from '@ionic-native/media-capture/ngx';
import { File } from '@ionic-native/file/ngx';
import { QuillModule } from 'ngx-quill'


declare var jquery: any;



@Directive({
  selector: '[dynamicPage]'
})
export class DynamicPageDirective {
  constructor(
    private compiler: Compiler,
    private viewContainer: ViewContainerRef
  ) { }


  public addComponent(pageToLoad: Page, queryStringparams: QueryStringParameter[]) {



    @Component({ template: pageToLoad.Template, encapsulation: ViewEncapsulation.Emulated })
    class TemplateComponent {

      isUploading: boolean;
      user: User = new User;
      isBusy: boolean;
      message: string;
      pageBag: any = {};
      dynamicpage: Page = pageToLoad;
      functions: PageFunction[] = [];
      tables: DictionaryItem<string, DataTable>[] = [];
      nonQueries: DictionaryItem<string, NonQuery>[] = [];
      objects: DictionaryItem<string, SingleObject>[] = [];
      doQueryResults: DictionaryItem<string, DataTable>[] = [];
      received: number = 0;
      opened: number = 0;
      upcoming: number = 0;

      //Editor = DecoupledEditor;

      queryParams: QueryStringParameter[];
      mobiscrollObj = mobiscroll;
      // mbSettings: MbscEventcalendarOptions;
      @ViewChild('fileButton', { static: false }) fileButton;


      constructor(
        private api: ApiService,
        private shell: ShellService,
        private router: Router,
        public nav: NavController,
        private toastr: ToastrService,
        private storage: Storage,
        private callNumber: CallNumber,
        private imagePicker: ImagePicker,
        private fTP: FTP,
        public messaging: MessagingService,
        private camera: Camera,
        private mediaCapture: MediaCapture,
        private file: File
      ) { }
      public onReady( editor ) {
        editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
        );
    }
      ngOnInit() {
        mobiscroll.settings = {
          theme: 'ios'
        };

        this.queryParams = queryStringparams;
        this.loadPage();
      }

      SetSurveyCounts(open: number, upcoming: number, received: number) {
        let counts: SurveyCounts = new SurveyCounts();
        counts.open = open;
        counts.upcoming = upcoming;
        counts.received = received;

        this.messaging.publish<SurveyCounts>(counts);
      }

      loadPage() {

        this.api.get<PageFunction[]>('pages/getpagefunctionsbyroute/' + pageToLoad.Route).then(response => {

          this.functions = response;

          for (let f of this.functions) {
            if (f.ReturnTypeId == 1) { //void
              this.nonQueries[f.Name] = new NonQuery();
            }
            else if (f.ReturnTypeId == 2) { //dataTable
              this.tables[f.Name] = new DataTable();
            }
            else if (f.ReturnTypeId == 3) { //singleObject
              this.objects[f.Name] = new SingleObject();
            }
          }


          this.user = JSON.parse(localStorage.getItem('currentUser'));
          if (this.user.open) {
            this.opened = this.user.open;
          }
          if (this.user.upcoming) {
            this.upcoming = this.user.upcoming;
          }
          if (this.user.received) {
            this.received = this.user.received;
          }
          this.doInitFunctions();

          eval("var _this = this; " + pageToLoad.Script);
        });
      }

      
      public CallNumber(mumber: string) {
        this.callNumber.callNumber(mumber, true)
          .then(res => console.log('Launched dialer!', res))
          .catch(err => console.log('Error launching dialer', err));
      }

      showLoader(msg) {
        this.shell.loadingController.create({
          spinner: 'crescent',
          message: msg,
          translucent: true,
          mode: 'md',
          cssClass: 'loading-controller'
        }).then(loading => loading.present());
      }
    
      dismissLoader() {
        this.shell.loadingController.dismiss();
      }

      async uploadLocalVideo(file:any){
        return new Promise(async (resolve) => {
          let res:any = await this.file.resolveLocalFilesystemUrl(file.localURL);
          res.file((resFile) => {
            let reader = new FileReader();
            reader.readAsDataURL(resFile);
            reader.onloadend = async (evt: any) => {
              let encodingType = "";
              /*
               * File reader provides us with an incorrectly encoded base64 string.
               * So we have to fix it, in order to upload it correctly.
               */
              let OriginalBase64 = evt.target.result.split(',')[1]; // Remove the "data:video..." string.
              let decodedBase64 = atob(OriginalBase64); // Decode the incorrectly encoded base64 string.
              let encodedBase64 = btoa(decodedBase64); // re-encode the base64 string (correctly).
              let newBase64 = encodingType + encodedBase64; // Add the encodingType to the string.
              resolve(newBase64);
            }
          });
        });
      }

      async uploadVideo(file:any){
        this.showLoader('Uploading...');
        var filename = file.name;
        var dirpath = file.fullPath;
        
        try {
          var dirUrl = await this.file.resolveDirectoryUrl(dirpath);
          var retrievedFile = await this.file.getFile(dirUrl, filename, {});

        } catch(err) {
          this.dismissLoader();
          console.log(err);
        }
        
        return retrievedFile.file( data => {
            this.dismissLoader();
            console.log(data);
            return data;
        });
      }

      upload(fileArea: string, fileType: string, newFileName: string, content: string) {
        return this.api.postAs<UploadResponse>('data/doupload',
          {
            Name: newFileName,//this.fileToUpload.name, 
            Size: content.length,
            Data: content,
            FileArea: fileArea,
            FileType: fileType,
            FilesFolderPath: this.api.getfolderPath(),
            HttpPath: this.api.gethttpUrl(),
            UserId: this.user != null ? this.user.username : "Anonymous"
          })
          .then(response => {
            console.log(response);
            if (response.hasError) {
              console.log(response.errorMessage);
            }
            else {
              console.log("File Uploaded");
              return response;
            }
            this.isUploading = false;
          });
      }

      public UploadPhoto(options: any) {
        this.imagePicker.getPictures(options).then((results) => {
          for (var i = 0; i < results.length; i++) {
            console.log('Image URI: ' + results[i]);
          }
        }, (err) => { });
      }

      downloadFtpFile(localFile, remoteFile) {
        this.fTP.download(localFile, remoteFile).subscribe((result) => {
          console.log(result);
        }, (error) => {
          console.log(error)
        });
      }

      uploadFile() {
        this.fileButton.nativeElement.click();
      }
      fileChanged(event, afterUpload) {
        const files = event.target.files;
        console.log(files);
        const reader = new FileReader();
        reader.onload = () => {
          afterUpload(reader.result);
          //console.log(url);
        };
        reader.readAsDataURL(event.target.files[0]);
      }

      private doInitFunctions() {

        let fParams: RequestParameter[] = [];

        for (let f of this.functions) {
          if (f.OnLoad) {

            for (let p of f.Parameters) {

              for (let qsp of queryStringparams) {
                if (qsp.Name == p.Name && p.DefaultValue != null && p.DefaultValue.startsWith('?')) {
                  let rp = new RequestParameter();
                  rp.Name = qsp.Name;
                  rp.Value = qsp.Value;
                  fParams.push(rp);
                }
              }

            }

            if (fParams.length == 0 && f.Parameters.length > 0) {
              for (let p of f.Parameters) {
                if (p.DefaultValue != null) {
                  let rp = new RequestParameter();
                  rp.Name = p.Name;

                  if (p.DefaultValue.startsWith('$Global.')) {

                  }
                  else {
                    rp.Value = p.DefaultValue;
                  }
                  fParams.push(rp);
                }
              }
            }

            if (f.ReturnTypeId == 1) { //void
              console.log('init void ' + f.Name);
              this.nonQueries[f.Name].isBusy = true;
              this.nonQueries[f.Name].isLoading = true;

              if (f.BeforeExec != null) {
                eval(f.BeforeExec);
              }

              this.api.putAs<NonQuery>('functions/executeasnonquery/' + f.Id, fParams).then(response => {
                this.nonQueries[f.Name] = response;
                this.nonQueries[f.Name].isBusy = false;
                this.nonQueries[f.Name].isLoading = false;
                this.nonQueries[f.Name].isLoaded = true;

                if (f.OnSuccess != null) {
                  eval(f.OnSuccess);
                }
              })
                .catch(
                  () => {
                    if (f.OnError != null) {
                      eval(f.OnError);
                    }
                  });
            }
            else if (f.ReturnTypeId == 2) { //dataTable
              console.log('init dataTable ' + f.Name);
              this.tables[f.Name].isBusy = true;
              this.tables[f.Name].isLoading = true;

              if (f.BeforeExec != null) {
                eval(f.BeforeExec);
              }

              this.api.putAs<DataTable>('functions/executeasdatatable/' + f.Id, fParams).then(response => {
                this.tables[f.Name] = response;
                this.tables[f.Name].isBusy = false;
                this.tables[f.Name].isLoading = false;
                this.tables[f.Name].isLoaded = true;

                if (f.OnSuccess != null) {
                  eval(f.OnSuccess);
                }
              })
                .catch(
                  () => {
                    if (f.OnError != null) {
                      eval(f.OnError);
                    }
                  });
            }
            else if (f.ReturnTypeId == 3) { //singleObject
              console.log('init singleObject ' + f.Name);
              this.objects[f.Name].isBusy = true;
              this.objects[f.Name].isLoading = true;

              if (f.BeforeExec != null) {
                eval(f.BeforeExec);
              }

              this.api.putAs<SingleObject>('functions/executeassingleobject/' + f.Id, fParams).then(response => {
                this.objects[f.Name] = response;
                this.objects[f.Name].isBusy = false;
                this.objects[f.Name].isLoading = false;
                this.objects[f.Name].isLoaded = true;

                if (f.OnSuccess != null) {
                  eval(f.OnSuccess);
                }
              })
                .catch(
                  () => {
                    if (f.OnError != null) {
                      eval(f.OnError);
                    }
                  });
            }
          }
        }
      }

      public do(functionName: string, o: any): Promise<boolean> {

        this.isBusy = true;

        let f = this.functions.find(x => x.Name == functionName);
        if (!f) {
          console.log('Function ' + functionName + ' was not found.');
          return;
        }

        console.log('do ' + f.Name);

        let fParams: RequestParameter[] = [];

        if (o != null) {
          for (let p of f.Parameters) {

            let rp = new RequestParameter();
            rp.Name = p.Name;

            let pval = Reflect.get(o, p.Name);

            if (pval) {
              console.log('setting ' + p.Name + ' = ' + pval);
              rp.Value = pval;
            }
            else {

              if (p.DefaultValue != null) {
                // if(p.DefaultValue.startsWith('$Global.')) {
                //     let gVal = this.getGlobal(p.DefaultValue);
                //     console.log('setting ' + p.Name + ' = ' + gVal);
                //     rp.Value = gVal;
                // }
              }

              console.log('setting ' + p.Name + ' = NULL');
            }

            fParams.push(rp);
          }
        }

        console.log('calling api');
        if (f.ReturnTypeId == 1) { //void
          this.nonQueries[f.Name].isBusy = true;
          this.nonQueries[f.Name].isLoading = true;

          if (f.BeforeExec != null) {
            eval(f.BeforeExec);
          }

          this.api.putAs<NonQuery>('functions/executeasnonquery/' + f.Id, fParams).then(response => {
            console.log("here is the response");
            console.log(response);
            this.nonQueries[f.Name] = response;
            this.nonQueries[f.Name].isBusy = false;
            this.nonQueries[f.Name].isLoading = false;
            this.nonQueries[f.Name].isLoaded = true;
            this.isBusy = false;

            if (f.OnSuccess != null) {
              eval(f.OnSuccess);
            }
          })
            .catch(
              () => {
                if (f.OnError != null) {
                  eval(f.OnError);
                }
              });
        }
        else if (f.ReturnTypeId == 2) { //dataTable
          this.tables[f.Name].isBusy = true;
          this.tables[f.Name].isLoading = true;

          if (f.BeforeExec != null) {
            eval(f.BeforeExec);
          }

          this.api.putAs<NonQuery>('functions/executeasdatatable/' + f.Id, fParams).then(response => {
            console.log("here is the response");
            console.log(response);
            this.tables[f.Name] = response;
            this.tables[f.Name].isBusy = false;
            this.tables[f.Name].isLoading = false;
            this.tables[f.Name].isLoaded = true;
            this.isBusy = false;

            if (f.OnSuccess != null) {
              eval(f.OnSuccess);
            }
          })
            .catch(
              () => {
                if (f.OnError != null) {
                  eval(f.OnError);
                }
              });
        }
        else if (f.ReturnTypeId == 3) { //singleObject
          this.objects[f.Name].isBusy = true;
          this.objects[f.Name].isLoading = true;

          if (f.BeforeExec != null) {
            eval(f.BeforeExec);
          }

          this.api.putAs<SingleObject>('functions/executeassingleobject/' + f.Id, fParams)

            .then(
              response => {

                console.log("here is the response");
                console.log(response);
                this.objects[f.Name] = response;
                this.objects[f.Name].isBusy = false;
                this.objects[f.Name].isLoading = false;
                this.objects[f.Name].isLoaded = true;
                this.isBusy = false;

                if (f.OnSuccess != null) {
                  eval(f.OnSuccess);
                }
              })
            .catch(
              () => {
                if (f.OnError != null) {
                  eval(f.OnError);
                }
              });
        }
      }

      public doQuery(functionName: string, o: any, pageBagName: string) {

        this.isBusy = true;


        console.log('doQuery: ' + functionName);

        console.log('calling api');

        this.doQueryResults[pageBagName] = new DataTable();

        this.doQueryResults[pageBagName].isBusy = true;
        this.doQueryResults[pageBagName].isLoading = true;

        if (o.length >= 1) {
          let newO: any = new Object;
          let stringObject: string;
          o.forEach(element => {
            newO[Object.keys(element)[0]] = element[Object.keys(element)[0]];
          });
          o = newO;
        }
        return this.api.postAs<any>('data/doquery/' + functionName, o)
          .then(
            response => {

              console.log("here is the response");
              console.log(response);
              this.doQueryResults[pageBagName] = response;
              this.doQueryResults[pageBagName].isBusy = false;
              this.doQueryResults[pageBagName].isLoading = false;
              this.doQueryResults[pageBagName].isLoaded = true;
              this.isBusy = false;

              console.log("Response save to doQueryResults['" + pageBagName + "']");
            })
          .catch(() => {
            console.log("Do Query Error")
          });
      }

      public open(url: string, params: any) {

        let pArray = Reflect.ownKeys(params);

        pArray.forEach(element => {
          url += '&' + element.toString() + '=' + Reflect.get(params, element.toString());
        });

        window.open(url);
      }

      public sendEmail(receipents: string, subject: string, body: string, attachment: string, fileName: string) {
        this.isBusy = true;

        let emailData: any = {};
        emailData.email = receipents;
        emailData.subject = subject;
        emailData.body = body;

        if (attachment != null && attachment.length > 0) {
          let attachmentsList: any = []
          let attachmentData: any = {};
          attachmentData.key = attachment
          attachmentData.value = fileName

          attachmentsList.push(attachmentData)

          emailData.attachments = attachmentsList
        }

        this.api.postAs<any>('Admin/SendMail/', emailData).then(x => {
          this.isBusy = false;
          console.log(x);
        });
      }


      public getDate() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        var day = date.getDate();
        var dayString = '';
        var month = date.getMonth() + 1;
        var monthString = '';
        var year = date.getFullYear();
        if (month < 10)
          monthString = '0' + month.toString();
        else
          monthString = month.toString();
        if (day < 10)
          dayString = '0' + day.toString();
        else
          dayString = day.toString();
        var dateString = year + '-' + monthString + '-' + dayString;

        return dateString;
      }

      public getFirstDateOfMonth() {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);

        var day = firstDay.getDate();
        var dayString = '';
        var month = firstDay.getMonth() + 1;
        var monthString = '';
        var year = firstDay.getFullYear();
        if (month < 10)
          monthString = '0' + month.toString();
        else
          monthString = month.toString();
        if (day < 10)
          dayString = '0' + day.toString();
        else
          dayString = day.toString();
        var dateString = year + '-' + monthString + '-' + dayString;

        return dateString;
      }

    }
   
 
    @Pipe({ name: 'safe' })
    class SafePipe implements PipeTransform {
      constructor(private sanitizer: DomSanitizer) { }
      transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    }

    @Pipe({ name: 'safeHtml' })
    class SafeHtmlPipe implements PipeTransform {
      constructor(private sanitizer: DomSanitizer) { }
      transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
      }
    }

    

    @NgModule({
      declarations: [
        TemplateComponent,
        SafePipe,SafeHtmlPipe
      ],
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        AuthenticationService,
        JwtHelperService,
        Camera,
        MediaCapture,
        File
      ],
      imports: [
        MbscModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        IonicModule,
        NgApexchartsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        QuillModule.forRoot()
      ]
    })
    class TemplateModule { }

    if (this.viewContainer.length > 0) {
      this.viewContainer.remove();
    }

    this.compiler.clearCache();


    const mod = this.compiler.compileModuleAndAllComponentsSync(TemplateModule);
    const factory = mod.componentFactories.find((comp) =>
      comp.componentType === TemplateComponent
    );
    //const footerFactory = mod.componentFactories.find((comp) =>
    //  comp.componentType === FooterComponent
    //);
    return this.viewContainer.createComponent(factory);
    //const footer = this.footerContainer.createComponent(footerFactory);
  }
}