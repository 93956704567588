import { Component, ViewChild ,ContentChild,ElementRef} from '@angular/core';
import { Router,  ActivatedRoute, Params ,NavigationEnd} from '@angular/router';
import { ApiService } from '../services/api.service';
import { QueryStringParameter, Page,User} from '../models/data.types';
import { DynamicPageDirective,DynamicHeaderDirective,DynamicFooterDirective} from  '../directives';
import { Headers } from '@angular/http';
import { ViewContainerRef, Compiler} from '@angular/core';
import { HttpHeaders} from "@angular/common/http";
import { Subscription,combineLatest } from "rxjs";
import { Observable } from 'rxjs/Observable';
import { map, startWith,withLatestFrom } from 'rxjs/operators';
import { IonicModule ,NavController} from '@ionic/angular';
import { BrowserModule, Title, DomSanitizer } from '@angular/platform-browser';
import {Location} from '@angular/common';

@Component({
  selector: 'app-upcoming',
  templateUrl: 'upcoming.page.html',
  styleUrls: ['upcoming.page.scss']
})
export class UpcomingPage {
  sub: any;
  pageName: string;
  pageTitle: string;
  pageId: number;
  page: Page = new Page();
  currentUser: any = {};
  pagename: string;
  title: string;


  @ViewChild(DynamicHeaderDirective) dynamicHeader: DynamicHeaderDirective;
  @ViewChild(DynamicPageDirective) dynamicPage  : DynamicPageDirective;
  @ViewChild(DynamicFooterDirective) dynamicFooter: DynamicFooterDirective;
  
  constructor(private compiler: Compiler,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public nav: NavController,
    private sanitizer: DomSanitizer,
    private _location: Location) {

  }

  back(){
    this._location.back();
  }

  ionViewWillEnter() {
    //this.sub = this.route.params.subscribe(params => {

      this.pagename='mobile-upcoming';

      let body = JSON.parse('{"route": "'+this.pagename +'"}');
      this.LoadDynamicPage(this.pagename,this.dynamicHeader,this.dynamicPage,this.dynamicFooter);  
   //});
  }

  LoadDynamicPage(pageName : String,
    dynamicHeaderDirective : DynamicHeaderDirective ,
    dynamicPageDirective : DynamicPageDirective,
    dynamicFooterDirective : DynamicFooterDirective) 
{
    let currentUser : User;
    currentUser = JSON.parse(localStorage.getItem('currentUser'));
    
    if (currentUser != null) {

      this.apiService.authHeaders = new HttpHeaders();
      this.apiService.authHeaders.append('Content-Type', 'application/json');
      this.apiService.authHeaders.append(
        'Authorization', 'bearer ' + currentUser.token
      );
    }

    

    return this.apiService.get<Page>('pages/getpagebyroute/' + pageName).then(response => {
   
      let queryStringparams: QueryStringParameter[] = [];

      let pArray = Reflect.ownKeys(this.route.snapshot.queryParams);

      pArray.forEach(element => {
        let qsp = new QueryStringParameter();
        qsp.Name = element.toString();
        qsp.Value = Reflect.get(this.route.snapshot.queryParams, element.toString());
        queryStringparams.push(qsp);
      });

      this.page = response;

      this.page.Header = response.Header === null ?`<ion-header>
      <ion-toolbar  style="--min-height:75px">
        <ion-buttons slot="start" style="width:20%;position:absolute;bottom:2px">
        </ion-buttons>
        <ion-title><ion-img src="assets/imgs/logo/Eliant Ex Manag.png" style="height:80px"></ion-img></ion-title>
        <ion-buttons slot="end" style="width:20%;position:absolute;right:0;bottom:10px;color:#aeb0ae">
        <ion-icon slot="icon-only" name="search" style="font-size:32px" [routerLink]="['/tabs/tab/main/search']"></ion-icon>
              </ion-buttons>
        </ion-toolbar>
      
    </ion-header>` 
      : response.Header;

     var mainComponent = dynamicPageDirective.addComponent(this.page,queryStringparams);
     
     dynamicHeaderDirective.addHeader(this.page.Title,this.page.Header,mainComponent.instance);


    }).catch(e => {
        if (e.status === 401) {
            //  this.router.navigate(['/page/login']);
            console.log(e);

            this.apiService.get<Page>('pages/getpagebyroute/401').
            then(response => {
              dynamicPageDirective.addComponent(response,null);
            });

            
            return "";
          }
          else
          {
            return "404";
          }
       
      });
    
    };

}
